import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DRFQueryOptions } from '@archinsurance-viki/property-jslib/src/ts-types/ApiTypes';
import { RowValidationType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { updateSubmissionData } from '../../actions/SubmissionActions';
import { UPDATE_ROW_DATA } from '../../constants/ActionTypes';
import { LITE_TABLES } from '../../constants/LiteTableConfigs';
import { RepriceValidations, SubmissionOverviewGlossaryResult, SubmissionOverviewResult, SubmissionResult } from '../../ts-types/ApiTypes';
import { SubmissionDataType } from '../../ts-types/DataTypes';
import api from '../apiSlice';
import { buildIncludesExcludesParams } from '../utils';
import { RecursivePartial } from '@archinsurance-viki/property-jslib/src/ts-types/util';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { updateGlossaryObject } from '@archinsurance-viki/property-jslib/src/utils/api-helpers';
import { TenantGlossaryItemType } from '../../ts-types/ApiTypes';

const DEFAULT_SUBMISSION_INCLUDES = [
    'quotes.*',
    'quotes.associated_policies.*',
    'quotes.final_carrier_data.*',
    'quotes.final_premium.*',
    'quotes.policy_coverage.*',
    'insured.possible_duplicate_sids',
    'additional_named_insureds.*',
    'archlink_communication_statuses.*',
    'is_live_production_submission',
    '_actions.*',
];

const DEFAULT_SUBMISSION_PARAMS = {
    'filter{quotes|is_deleted}': '0',
    show_possible_duplicate_sids: '1',
};

export const DEFAULT_VALIDATIONS = [] as (keyof RepriceValidations)[];

export const submissionApi = api.injectEndpoints({
    endpoints: builder => ({
        submissionData: builder.query<SubmissionResult['submission'], { id: number } & DRFQueryOptions>({
            query: ({ id, includes = DEFAULT_SUBMISSION_INCLUDES, filter = '', excludes = [], params = DEFAULT_SUBMISSION_PARAMS }) => ({
                url: `v2/submission/${id}/`,
                method: 'GET',
                params: buildIncludesExcludesParams({ params, filter, includes, excludes }),
            }),
            transformResponse: (result: SubmissionResult) => ({
                ...result.submission,
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                const submissionData = { ...data, quotes: [] } as SubmissionDataType;
                if ('quotes' in data) {
                    submissionData['quotes'] = data.quotes.map(({ id }) => id);
                    // ensure quote data is updated
                    // For example: Change quote description on Policy Terms page then navigate to Coverage Options List page
                    data.quotes.forEach(quote => {
                        dispatch({
                            type: UPDATE_ROW_DATA,
                            tableConfig: LITE_TABLES.QUOTES,
                            data: quote,
                        });
                    });
                } else {
                    delete submissionData.quotes;
                }
                // TODO: Rob to stop using this because it conflicts with data from account_transaction_data
                const account_id = data?.account_id ?? id;
                dispatch(updateSubmissionData(id, { ...submissionData, account_id, SUPPLEMENTED: true }));
            },
            providesTags: (result, _err, arg) => {
                if (!result) {
                    return [];
                }
                const tags: TagDescription<'Submission' | 'Quote'>[] = [{ type: 'Submission', id: arg.id }];
                if ('quotes' in result && result.quotes) {
                    tags.push(...result.quotes.map(q => ({ type: 'Quote', id: q.id } as TagDescription<'Quote'>)));
                }
                return tags;
            },
        }),
        submissionOverviewData: builder.query<SubmissionOverviewResult, { submissionId: number }>({
            query: ({ submissionId }) => ({
                url: `v1/submission_overview_data/${submissionId}/`,
                method: 'get',
                hideDialogForErrors: true,
            }),
            providesTags: (_result, _err, { submissionId }) => [
                { type: 'SubmissionOverview', id: submissionId },
                { type: 'Submission', id: submissionId },
            ],
        }),
        saveSubmissionOverviewData: builder.mutation<Record<string, unknown>, { submissionId: number; data: Record<string, unknown> }>({
            query: ({ submissionId, data }) => ({
                url: `v1/submission_overview_data/batch_update/`,
                method: 'put',
                data: { data, pks: [submissionId] },
                hideDialogForErrors: true,
            }),
            async onQueryStarted({ submissionId, data }, { dispatch, queryFulfilled }) {
                const pcPatchResult = dispatch(
                    submissionApi.util.updateQueryData('submissionOverviewData', { submissionId }, draft => {
                        Object.keys(data).forEach(key => {
                            draft[key] = data[key];
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch (e) {
                    pcPatchResult.undo();
                    throw e;
                }
            },
            invalidatesTags: (result, _err, { submissionId }) =>
                result
                    ? [
                          { type: 'SubmissionOverview', id: submissionId },
                          { type: 'Submission', id: submissionId },
                          { type: 'Quote', id: result.quote_id as number },
                          { type: 'PolicyCoverage', id: result.policy_coverage_id as number },
                          { type: 'FinalPremium', id: result.final_premium_id as number },
                      ]
                    : [],
        }),
        submissionOverviewGlossary: builder.query<SubmissionOverviewGlossaryResult, Record<string, never>>({
            query: () => ({
                url: 'v1/submission_overview_data/glossary/',
                method: 'get',
            }),
        }),
        submissionOverviewDetailGlossary: builder.query<SubmissionOverviewGlossaryResult, { id: number }>({
            query: ({ id }) => ({
                url: `v1/submission_overview_data/${id}/detail_glossary/`,
                method: 'get',
            }),
        }),
        oldSubmissionOverviewGlossary: builder.query<Record<string, TenantGlossaryItemType>, Record<string, never>>({
            query: () => ({
                url: 'v1/glossary',
                method: 'get',
                params: { tables: PAGED_TABLES.SUBMISSION_LOG.tableName },
            }),
            transformResponse: data => {
                if (!data?.[0]) {
                    return null;
                }
                return updateGlossaryObject(data[0]).map;
            },
        }),
        validateSubmission: builder.query<RowValidationType, { submissionId: number; quoteId?: number; validations?: (keyof RepriceValidations)[] }>({
            query: ({ submissionId, quoteId }) => ({
                url: `v1/submission/${submissionId}/validate/`,
                params: {
                    coverage_option_id: quoteId,
                },
                method: 'get',
            }),
            async onQueryStarted({ submissionId, quoteId }, { queryFulfilled, dispatch }) {
                const { data } = await queryFulfilled;
                const updateData = { _validations: { ...data } } as RecursivePartial<SubmissionDataType>;
                if (quoteId !== undefined) {
                    updateData._validations._currentQuoteId = quoteId;
                }
                dispatch(updateSubmissionData(submissionId, updateData));
            },
            providesTags: (_result, _, args) =>
                args.quoteId
                    ? [
                          { type: 'SubmissionValidation', id: args.submissionId },
                          { type: 'QuoteValidation', id: args.quoteId },
                      ]
                    : [{ type: 'SubmissionValidation', id: args.submissionId }],
        }),
    }),
});

export const {
    useSubmissionDataQuery,
    useSubmissionOverviewDataQuery,
    useSaveSubmissionOverviewDataMutation,
    useOldSubmissionOverviewGlossaryQuery,
    useSubmissionOverviewGlossaryQuery,
    useSubmissionOverviewDetailGlossaryQuery,
    useValidateSubmissionQuery,
    useLazyValidateSubmissionQuery,
} = submissionApi;
