import lockr from 'lockr';
import React, { useRef, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import API from '@archinsurance-viki/property-jslib/src/utils/API';
import BuildingCoverageContainer from '../../components/building-coverage/BuildingCoverageContainer';
import DocumentsPage from '../../components/documents/DocumentsContainer';
import GridEditor from '../../components/grideditor/GridEditorApp';
import OverviewPage from '../../components/overview/OverviewContainer';
import { PolicyTermsContainer } from '../../features/coverage-terms/PolicyTermsApp';
import SubmissionLog from '../../components/submissionlog/SubmissionLogContainer';
import TransactionsPage from '../../components/transactions/TransactionsContainer';
import BlanketLimits from '../../components/underwriting/BlanketLimitsContainer';
import BuildingPremiumPage from '../../components/underwriting/BuildingPremiumPageContainer';
import BuildingQuoteConditionsPage from '../../components/underwriting/BuildingQuoteConditionsContainer';
import CoverageOptionsPage from '../../components/underwriting/CoverageOptionsListContainer';
import ExposureSummaryPage from '../../components/underwriting/ExposureSummaryPageContainer';
import PolicyFormsPagePage from '../../components/underwriting/PolicyFormsPageContainer';
import QuoteCondBindingReqPage from '../../components/underwriting/QuoteCondBindingReqPageContainer';
import AccountsTaskPageContainer from '../../components/tasks/AccountTasks.container';
import ReferralHistoryContainer from '../../components/referral-history/ReferralHistoryContainer';
import TivGuidelinesPage from '../../components/underwriting/TivGuidelinesContainer';
import { RECENT_TAB_ACCOUNT_ID_CACHE } from '../../constants/CacheConstants';
import { NAVIGATION_LOCATIONS } from '../../constants/Navigation';
import AccountsAppContainer from '../../containers/AccountsAppContainer';
import { showCoverageOptions } from '../../constants/Navigation';
import { useAppContext } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { toggleSkipRevalidation } from '../../reducers/pricing';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { BindingRequirements } from '../underwriting/BindingRequirements';

import { PricingPage as PricingPageV2 } from '../../features/pricing/PricingPage';
import { ModelingAnalysisPage } from '../../features/pricing/ModelingPage';
import RegionalCATSummaryContainer from '../regional-cat-summary/RegionalCATSummaryContainer';
import { ClaimsPage } from '../../features/claims/ClaimsPage';

type RoutePropTypes = {
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
    noCurrentSubmission?: boolean;
    hasLeftPanel?: boolean;
};

const TRANSACTION_ROUTE_PROPS: RoutePropTypes = {
    transactionPulldown: TRANSACTION_PULLDOWN_STATES.HIDDEN,
    noCurrentSubmission: true,
};
const AC_PROPS: RoutePropTypes = {
    transactionPulldown: TRANSACTION_PULLDOWN_STATES.ACCOUNT_CORRECTIONS,
};
const HIDE_TRANSACTION_PICKER: RoutePropTypes = {
    transactionPulldown: TRANSACTION_PULLDOWN_STATES.HIDDEN,
};

const SUBMISSIONS_PATH = '/submissions';

export default function AccountsAppRoutes() {
    const skipRevalidation = useAppSelector(state => state.pricing.skipRevalidation);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const match = useRouteMatch<{ accountId?: string }>();
    const lastReportedUrl = useRef<string>();
    const appContext = useAppContext();
    const featureFlags = useAppSelector(state => state.global.featureFlags);

    // TODO: Remove when grid stuff is refactored and we handle validation is_dirty properly
    useEffect(() => {
        if (location.pathname.endsWith('pricing')) {
            dispatch(toggleSkipRevalidation(true));
        } else if (skipRevalidation) {
            dispatch(toggleSkipRevalidation(false));
        }
    }, [dispatch, location.pathname, skipRevalidation]);

    useEffect(() => {
        // skip the submission grid, because it's not enough to count as a hit.
        if (match.path === SUBMISSIONS_PATH) return;

        let accountId = +match.params?.accountId;
        let url = match.url;
        if (!accountId || isNaN(accountId)) return;
        if (!url) return;
        if (url.includes('undefined')) return;

        if (url === lastReportedUrl.current)
            // skip if we just reported this accountId, no need to re-report.
            return;

        lastReportedUrl.current = url;
        lockr.set(RECENT_TAB_ACCOUNT_ID_CACHE, accountId);

        const logPageView = async () => {
            try {
                await API.doRequest(
                    API.endpoints.userAccessLog.viewingAccount,
                    {
                        id: accountId,
                        route: match,
                    },
                    dispatch
                );
            } catch (err) {
                console.error(err);
            }
        };
        logPageView();
    }, [dispatch, match]);

    const renderRoute = (children: React.ReactNode, { hasLeftPanel, noCurrentSubmission, transactionPulldown }: RoutePropTypes = {}): React.ReactNode => {
        return (
            <AccountsAppContainer hasLeftPanel={hasLeftPanel} noCurrentSubmission={noCurrentSubmission} transactionPulldown={transactionPulldown}>
                {children}
            </AccountsAppContainer>
        );
    };

    const { currentSubmission, currentAccountId, currentTransactionId } = appContext;

    return (
        <Switch>
            <Route path="/submissions/:accountId/:submissionId/documents">{renderRoute(<DocumentsPage />, HIDE_TRANSACTION_PICKER)}</Route>
            <Route path="/submissions/:accountId/:submissionId/exposures">{renderRoute(<ExposureSummaryPage />)}</Route>
            <Route path="/submissions/:accountId/:submissionId/tasks">{renderRoute(<AccountsTaskPageContainer />)}</Route>
            {showCoverageOptions(appContext) === false && (
                // VIKI-6724
                <Redirect from="/submissions/:accountId/:submissionId/underwriting" to="/submissions/:accountId/transactions/" />
            )}
            <Route path="/submissions/:accountId/:submissionId/regional-cat-summary">{renderRoute(<RegionalCATSummaryContainer />)}</Route>
            <Redirect
                from="/submissions/:accountId/:submissionId/underwriting/:quoteId/pricingV2"
                to="/submissions/:accountId/:submissionId/underwriting/:quoteId/pricing"
            />
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/pricing">{renderRoute(<PricingPageV2 />, AC_PROPS)}</Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/claims">
                <ClaimsPage />
            </Route>
            <CompatRoute path="/submissions/:accountId/:submissionId/underwriting/:quoteId/modeling" component={ModelingAnalysisPage} />
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/premiums/:buildingId?">
                {renderRoute(<BuildingPremiumPage />, AC_PROPS)}
            </Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/guidelines">{renderRoute(<TivGuidelinesPage />)}</Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/pipeline">{renderRoute(<QuoteCondBindingReqPage />)}</Route>
            {featureFlags?.binding_requirements_v2 && (
                <CompatRoute path="/submissions/:accountId/:submissionId/underwriting/:quoteId/binding_requirements" component={BindingRequirements} />
            )}
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/forms">{renderRoute(<PolicyFormsPagePage />)}</Route>
            <CompatRoute path="/submissions/:accountId/:submissionId/underwriting/:quoteId/referral_history" component={ReferralHistoryContainer} />
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/coverage_options/buildings/:buildingId?">
                {renderRoute(<BuildingCoverageContainer />)}
            </Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/coverage_options/buildings">
                {renderRoute(<BuildingCoverageContainer />)}
            </Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/policy_terms">{renderRoute(<PolicyTermsContainer />)}</Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/policy_terms">{renderRoute(<PolicyTermsContainer />)}</Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/quoteconditions">{renderRoute(<BuildingQuoteConditionsPage />)}</Route>
            <Route path="/submissions/:accountId/:submissionId/underwriting/:quoteId/limits/:buildingId?">{renderRoute(<BlanketLimits />)}</Route>
            <Redirect from="/submissions/:accountId/transactions/:transactionId/pricingV2" to="/submissions/:accountId/transactions/:transactionId/pricing" />
            <Route path="/submissions/:accountId/transactions/:transactionId/pricing">{renderRoute(<PricingPageV2 />, TRANSACTION_ROUTE_PROPS)}</Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/claims">
                <ClaimsPage />
            </Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/premiums/:buildingId?">
                {renderRoute(<BuildingPremiumPage isTransactionRoute={true} />, TRANSACTION_ROUTE_PROPS)}
            </Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/guidelines">{renderRoute(<TivGuidelinesPage />, TRANSACTION_ROUTE_PROPS)}</Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/pipeline">
                {renderRoute(<QuoteCondBindingReqPage />, TRANSACTION_ROUTE_PROPS)}
            </Route>
            {featureFlags?.binding_requirements_v2 && (
                <CompatRoute path="/submissions/:accountId/transactions/:transactionId/binding_requirements" component={BindingRequirements} />
            )}
            <Route path="/submissions/:accountId/transactions/:transactionId/forms">{renderRoute(<PolicyFormsPagePage />, TRANSACTION_ROUTE_PROPS)}</Route>
            <CompatRoute path="/submissions/:accountId/transactions/:transactionId/referral_history" component={ReferralHistoryContainer} />
            <Route path="/submissions/:accountId/transactions/:transactionId/quoteconditions">
                {renderRoute(<BuildingQuoteConditionsPage />, TRANSACTION_ROUTE_PROPS)}
            </Route>
            <Route path="/submissions/:accountId/transactions/:transactionId/limits/:buildingId?">
                {renderRoute(<BlanketLimits />, TRANSACTION_ROUTE_PROPS)}
            </Route>
            <Route path="/submissions/:accountId/transactions/:transactionId?">{renderRoute(<TransactionsPage />, TRANSACTION_ROUTE_PROPS)}</Route>
            <Route path={NAVIGATION_LOCATIONS.COVERAGE_OPTION.PATH}> {renderRoute(<CoverageOptionsPage />)}</Route>
            <Route path="/submissions/:accountId/:submissionId/editor/:buildingId?"> {renderRoute(<GridEditor />)}</Route>
            <Route path="/grid/:submissionId/:buildingId?"> {renderRoute(<GridEditor />)}</Route>
            {/*
                VIKI-9882, PRUW-4003

                This one's bizarre. This is a hacky bandaid for a situation where the Tasks page provides a link to a transaction,
                but the transaction id is the :accountId in `/submissions/:accountId/current/overview`.

                Instead of fixing the root cause, we put a bandaid on and redirect if we think the submission.account_id should be the :accountId

                But for an unknown reason, you can hit a situation (seemingly not in prod???) where the currentSubmission.account_id can point to the
                actual account id and then consequently points to the transaction id. Which caused an infinite redirect. But it also makes no sense.

                Two things to investigate:
                1) Why does the account_id change after moving the transaction id from :accountId to :submissionId?
                2) Why are we providing bad links in the task page in the first place?

                What this condition is checking for:
                1) Is our currentSubmission a transaction? Note that `getCurrentSubmission()` sees we have a NO_ACCOUNT and treats our transaction as a submission
                as expected.
                2) Is our currentTransactionId null even though we're definitely on a transaction? This, I BELIEVE, is only the case when currentAccount == NO_ACCOUNT
                3) Is our currentAccountId not matching the transaction.account_id?
                4) Then we redirect and hopefully there's no infinite redirection!
            */}
            {currentSubmission &&
                currentSubmission.transaction_status &&
                currentTransactionId === null &&
                currentAccountId &&
                currentAccountId !== currentSubmission.account_id && (
                    <Redirect from={NAVIGATION_LOCATIONS.OVERVIEW.PATH} to={`/submissions/${currentSubmission.account_id}/${currentAccountId}/overview`} />
                )}
            <Route path={[NAVIGATION_LOCATIONS.OVERVIEW.PATH, '/submissions/:accountId']}> {renderRoute(<OverviewPage />)}</Route>
            <Route path={SUBMISSIONS_PATH}>{renderRoute(<SubmissionLog />, HIDE_TRANSACTION_PICKER)}</Route>
            <Redirect to="/submissions" />
        </Switch>
    );
}
