import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { FormFormatSelection } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormFormatSelection';
import { useWatch } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, selectDeductibleFormats, selectSortedAlphabeticalChoices } from '../utils/form';
import { getSublimitShare, OCCURRENCE_FIELDS, trimAndParse, usePolicyTermsContext } from '../utils/coverage-utils';
import { DeductibleFormatsType, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { filterInvalidChoicesFromConstant } from '../../../selectors/constant';

export const WindDeductibles = () => {
    const { policyTermsGlossary } = usePolicyTermsContext();

    const CAT_WIND_DED_PERCENT_AMT = useAppSelector(state => state.global.CONSTANTS.CAT_WIND_DED_PERCENT_AMT);
    const CAT_WIND_DEDUCTIBLE = useAppSelector(state => filterInvalidChoicesFromConstant(state, 'CAT_WIND_DEDUCTIBLE', ['NONE']));
    const DEDUCTIBLE_APPLICABILITY = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'DEDUCTIBLE_APPLICABILITY'));
    const deductibleFormats = useAppSelector(state => selectDeductibleFormats(state));
    const featureFlags = useAppSelector(state => state.global.featureFlags);

    const windDeductibleType = useWatch<PolicyTermsFormValues, 'cw_deductible'>({ name: 'cw_deductible' });
    const applyMinimumOccurrenceDeductible = useWatch<PolicyTermsFormValues, 'cw_min_deductible'>({ name: 'cw_min_deductible' });
    const applyMaximumOccurrenceDeductible = useWatch<PolicyTermsFormValues, 'cw_max_deductible'>({ name: 'cw_max_deductible' });
    const deductibleType = CAT_WIND_DEDUCTIBLE.find(deductibleChoice => deductibleChoice.value === windDeductibleType)?.display ?? 'Named Storm';
    const showVentusShareWindSublimit = featureFlags?.show_ventus_share_wind_sublimit || false;
    const showBIDeductibleIncludedInWind = featureFlags?.show_bi_deductible_included_in_wind || false;
    const windGroundUpSublimit = useWatch<PolicyTermsFormValues, 'ground_up_sublimit'>({ name: 'ground_up_sublimit' });
    const occurrenceValues = useWatch<PolicyTermsFormValues, typeof OCCURRENCE_FIELDS>({
        name: OCCURRENCE_FIELDS,
    });
    const wdbdIncluded = useWatch<PolicyTermsFormValues, 'wdbd_included'>({ name: 'wdbd_included' });
    const wdbdMinOccurrenceRetentionApplicable = useWatch<PolicyTermsFormValues, 'wdbd_min_occurrence_retention_applicable'>({
        name: 'wdbd_min_occurrence_retention_applicable',
    });
    const [
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    ] = occurrenceValues;

    const ventusShareWindSublimit = showVentusShareWindSublimit
        ? getSublimitShare(
              {
                  policy_is_layered,
                  occurrence_participation_fmt,
                  occurrence_participation: +occurrence_participation,
                  occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
                  occurrence_attachment: trimAndParse(occurrence_attachment),
                  occurrence_limit: trimAndParse(occurrence_limit),
              },
              trimAndParse(windGroundUpSublimit)
          )
        : '';

    return (
        <>
            <div className="info-rows">
                <PolicyTermsFormField>
                    <FormInput<PolicyTermsFormValues> name="ground_up_sublimit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
            </div>
            {ventusShareWindSublimit && (
                <div className="info-rows">
                    <div className="input-label flex">
                        <div className="txt-overflow">{policyTermsGlossary.wind_share_of_sublimit_label.label}</div>
                    </div>
                    <div className="note-info-blocks" style={{ marginBottom: 0, width: 'fit-content' }}>
                        <span className="tw-cursor-default">{ventusShareWindSublimit}</span>
                    </div>
                </div>
            )}
            <hr className="first:tw-hidden tw-col-span-full" />
            <div className="info-rows">
                <div className="input-label txt-overflow tw-text-grey-primary tw-font-semibold">Deductibles</div>
            </div>
            <div className="info-rows sub-row">
                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues> name="cw_deductible" items={CAT_WIND_DEDUCTIBLE} />
                </PolicyTermsFormField>

                <PolicyTermsFormField label={deductibleType}>
                    <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                        name="cw_deductible_fmt"
                        formats={deductibleFormats}
                        render={format =>
                            format === 'PERCENTAGE' ? (
                                <PolicyTermsFormField hideLabel>
                                    <FormSelect<PolicyTermsFormValues> name="cw_deductible_pct_amt" selections={CAT_WIND_DED_PERCENT_AMT} />
                                </PolicyTermsFormField>
                            ) : (
                                <PolicyTermsFormField hideLabel>
                                    <FormInput<PolicyTermsFormValues> name="cw_deductible_dollar_amt" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                                </PolicyTermsFormField>
                            )
                        }
                    />
                </PolicyTermsFormField>

                <PolicyTermsFormField>
                    <FormSelect<PolicyTermsFormValues> name="cw_deductible_appl" selections={DEDUCTIBLE_APPLICABILITY} />
                </PolicyTermsFormField>

                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="cw_min_deductible"
                        items={[
                            { display: 'Yes', value: true },
                            { display: 'No', value: false },
                        ]}
                    />
                </PolicyTermsFormField>

                {applyMinimumOccurrenceDeductible && (
                    <PolicyTermsFormField>
                        <FormInput<PolicyTermsFormValues> name="cw_min_deductible_dollar_amt" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                    </PolicyTermsFormField>
                )}

                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="cw_max_deductible"
                        items={[
                            { display: 'Yes', value: true },
                            { display: 'No', value: false },
                        ]}
                    />
                </PolicyTermsFormField>

                {applyMaximumOccurrenceDeductible && (
                    <PolicyTermsFormField>
                        <FormInput<PolicyTermsFormValues> name="cw_max_deductible_dollar_amt" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                    </PolicyTermsFormField>
                )}

                {windDeductibleType !== 'WIND_HAIL' && (
                    <>
                        <PolicyTermsFormField>
                            <FormButtonGroup<PolicyTermsFormValues>
                                name="cw_agg_deductible"
                                items={[
                                    { display: 'Yes', value: true },
                                    { display: 'No', value: false },
                                ]}
                            />
                        </PolicyTermsFormField>

                        {policyTermsGlossary.cw_secondary_deductible_fmt.active ? (
                            <>
                                <PolicyTermsFormField>
                                    <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                                        name="cw_secondary_deductible_fmt"
                                        formats={deductibleFormats}
                                        render={format =>
                                            format === 'PERCENTAGE' ? (
                                                <PolicyTermsFormField hideLabel>
                                                    <FormSelect<PolicyTermsFormValues>
                                                        name="cw_secondary_deductible_pct_amt"
                                                        selections={CAT_WIND_DED_PERCENT_AMT}
                                                    />
                                                </PolicyTermsFormField>
                                            ) : (
                                                <PolicyTermsFormField hideLabel>
                                                    <FormInput<PolicyTermsFormValues>
                                                        name="cw_secondary_deductible_dollar_amt"
                                                        dollarBefore
                                                        maskOptions={MASKS['CURRENCY_INTEGER']}
                                                    />
                                                </PolicyTermsFormField>
                                            )
                                        }
                                    />
                                </PolicyTermsFormField>
                            </>
                        ) : (
                            <PolicyTermsFormField>
                                <FormInput<PolicyTermsFormValues>
                                    name="cw_secondary_deductible_dollar_amt"
                                    dollarBefore
                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                />
                            </PolicyTermsFormField>
                        )}
                    </>
                )}
            </div>

            <div>
                <hr className="[&:has(+_div:empty)]:tw-hidden" />
                <div className="info-rows sub-row">
                    <PolicyTermsFormField>
                        <FormButtonGroup<PolicyTermsFormValues>
                            name="wdbd_included"
                            items={[
                                { display: 'Yes', value: true },
                                { display: 'No', value: false },
                            ]}
                        />
                    </PolicyTermsFormField>
                    {wdbdIncluded && (
                        <PolicyTermsFormField>
                            <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                                name="wdbd_retention"
                                formats={deductibleFormats}
                                render={format =>
                                    format === 'PERCENTAGE' ? (
                                        <PolicyTermsFormField hideLabel>
                                            <FormSelect<PolicyTermsFormValues> name="wdbd_retention_deductible_pct_amt" selections={CAT_WIND_DED_PERCENT_AMT} />
                                        </PolicyTermsFormField>
                                    ) : (
                                        <PolicyTermsFormField hideLabel>
                                            <FormInput<PolicyTermsFormValues>
                                                name="wdbd_retention_deductible_dollar_amt"
                                                dollarBefore
                                                maskOptions={MASKS['CURRENCY_INTEGER']}
                                            />
                                        </PolicyTermsFormField>
                                    )
                                }
                            />
                        </PolicyTermsFormField>
                    )}
                    {wdbdIncluded && (
                        <PolicyTermsFormField>
                            <FormSelect<PolicyTermsFormValues> name="wdbd_basis" selections={DEDUCTIBLE_APPLICABILITY} disabled={true} />
                        </PolicyTermsFormField>
                    )}
                    {wdbdIncluded && (
                        <PolicyTermsFormField labelClass="input-label flex">
                            <FormButtonGroup<PolicyTermsFormValues>
                                name="wdbd_min_occurrence_retention_applicable"
                                items={[
                                    { display: 'Yes', value: true },
                                    { display: 'No', value: false },
                                ]}
                            />
                        </PolicyTermsFormField>
                    )}
                    {wdbdIncluded && wdbdMinOccurrenceRetentionApplicable && (
                        <PolicyTermsFormField labelClass="input-label flex">
                            <FormInput<PolicyTermsFormValues> name="wdbd_min_occurrence_retention" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                        </PolicyTermsFormField>
                    )}
                </div>
            </div>

            <hr />
            <div className="info-rows no-marg-top">
                {showBIDeductibleIncludedInWind && (
                    <PolicyTermsFormField>
                        <FormButtonGroup<PolicyTermsFormValues>
                            name="is_bi_deductible_included_in_wind"
                            items={[
                                { display: 'Yes', value: true },
                                { display: 'No', value: false },
                            ]}
                        />
                    </PolicyTermsFormField>
                )}
                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="is_storm_surge_included_in_wind"
                        items={[
                            { display: 'Yes', value: true },
                            { display: 'No', value: false },
                        ]}
                    />
                </PolicyTermsFormField>

                {/* TODO */}
                {false && (
                    <>
                        <div className="input-label flex">
                            <div className="txt-overflow">Allow Pro-Rata Wind MEP</div>
                        </div>
                        <div className="flex tertiary-input">
                            <div className="data-entry-btn-wrapper min-width">
                                <button className="button data-entry">Yes</button>
                                <button className="button data-entry active">No</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
