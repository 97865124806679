import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { useAppContext } from '../../hooks/context';
import { Select } from '@archinsurance-viki/property-jslib/src/components/inputs/v2';
import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { formatDateString, formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { noop } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { DefaultAccountLayout } from '../../components/common/DefaultLayout';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useSedgwickClaimQuery } from '../../services/endpoints/coverage-option';

const formatCurrency = (value: number) => formatNumberIntl(value, { style: 'currency' });

// TODO: Before moving to jslib, allow for arbitrary choices
const RadioButtonInput = ({ value, name }: { value: 'yes' | 'no'; name: string }) => (
    <form className="tw-flex tw-gap-4">
        <div className="tw-flex tw-gap-1">
            <input type="radio" id={`${name}_yes`} value="yes" checked={value === 'yes'} name={name} disabled />
            <label htmlFor={`${name}_no`}>Yes</label>
        </div>

        <div className="tw-flex tw-gap-1">
            <input type="radio" id={`${name}_no`} value="no" checked={value === 'no'} name={name} disabled />
            <label htmlFor={`${name}_no`}>No</label>
        </div>
    </form>
);

const ClaimsRadioButtonField = ({ value, name, label }: { value: 'yes' | 'no'; name: string; label: string }) => {
    return (
        <>
            <label>{label}</label>
            <RadioButtonInput value={value} name={name} />
        </>
    );
};

const Breadcrumbs = () => (
    <div className="tw-flex tw-gap-1">
        <a>Coverage Options</a>
        <div>{'>'}</div>
        <div>Claims</div>
    </div>
);

const Section = ({
    children,
    header,
    canCollapse,
    defaultIsCollapsed = false,
}: {
    children?: React.ReactNode;
    header: React.ReactNode;
    subheader?: React.ReactNode;
    canCollapse?: boolean;
    defaultIsCollapsed?: boolean;
}) => {
    const contentRef = useRef<HTMLDivElement>();
    const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);

    const handleHeaderClick = () => {
        if (!canCollapse) {
            return;
        }
        setIsCollapsed(prev => !prev);
    };

    const collapsableHeader = isCollapsed ? <Icon icon="arrow_right" /> : <Icon icon="arrow_drop_down" />;

    const infoRowsElement = contentRef.current?.querySelector('.info-rows');
    const isEmptySection = !contentRef.current?.hasChildNodes() || (infoRowsElement && !infoRowsElement.hasChildNodes());

    return (
        <div className="info-block row-editable tw-p-0 tw-font-sans tw-font-normal">
            <div
                onClick={() => handleHeaderClick()}
                className={cx('tw-px-2 tw-py-1 tw-transition tw-delay-75 header tw-text-base tw-normal-case tw-font-normal tw-justify-normal', {
                    'tw-border-transparent': isCollapsed || isEmptySection,
                    'tw-cursor-pointer': canCollapse,
                })}
            >
                {canCollapse ? collapsableHeader : null}
                <div className="tw-flex tw-gap-4 tw-items-center tw-w-full">
                    <div className="tw-text-base header-text tw-font-semibold tw-w-full tw-font-sans">{header}</div>
                    {/*subheader && (
                        <>
                            <span className="tw-text-grey-light">|</span>
                            <div>{subheader}</div>
                        </>
                    )*/}
                </div>
            </div>
            <div
                ref={contentRef}
                className={cx('tw-text-base tw-transition-[all] tw-duration-300', {
                    'tw-overflow-hidden tw-max-h-0': isCollapsed,
                    'tw-max-h-[2000px]': !isCollapsed,
                })}
            >
                <div className="[&>*:not(#header)]:tw-p-2">{children}</div>
            </div>
        </div>
    );
};

const PolicyPeriodSection = ({
    children,
    data,
}: {
    children?: React.ReactNode;
    data: { policy_number: string; policy_period: string; number_claims: number; incurred: number }; // TODO: use actual data fields
}) => (
    <Section
        header={
            <div className="tw-grid tw-grid-cols-[280px_110px_325px_1fr] tw-text-base tw-font-sans tw-font-normal">
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Policy Number:</span>
                    <span>{data.policy_number}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Claims:</span>
                    <span>{data.number_claims}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Policy Period:</span>
                    <span>{data.policy_period}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Incurred:</span>
                    <span>{formatCurrency(data.incurred)}</span>
                </div>
            </div>
        }
        canCollapse
    >
        <div className="tw-w-full tw-p-2 tw-mb-2 tw-flex tw-flex-col tw-gap-4">
            <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2">
                <ClaimsField label="Total Claims" value={2} />
                <ClaimsField label="Policy Period" value="04/15/2024 - 04/15/2025" />
                <ClaimsField label="Loss Paid" value="1,000,000" isCurrency />
                <ClaimsField label="Loss Reserve" value={150000} isCurrency />
                <ClaimsField label="Expense Paid" value={25000} isCurrency />
                <ClaimsField label="Expense Reserve" value={10000} isCurrency />
                <ClaimsField label="Subrogation" value={-5000} isCurrency />
                <ClaimsField label="Total Incurred" value={1180000} isCurrency />
            </div>
            {children}
        </div>
    </Section>
);

const ClaimSection = ({
    data,
}: {
    children?: React.ReactNode;
    data: { claim_number: number; status: string; peril: string; incurred: number }; // TODO: use actual data fields
}) => (
    <Section
        header={
            <div className="tw-grid tw-grid-cols-[200px_120px_150px_1fr] tw-gap-2 tw-text-base  tw-font-sans tw-font-normal">
                <div className="tw-flex tw-gap-1">
                    <span className="tw-font-semibold">Claim Number:</span>
                    <span>{data.claim_number}</span>
                </div>
                <div className="tw-flex tw-gap-1">
                    <span className="tw-font-semibold">Status:</span>
                    <span>{data.status}</span>
                </div>
                <div className="tw-flex tw-gap-1">
                    <span className="tw-font-semibold">Incurred:</span>
                    <span>{formatCurrency(data.incurred)}</span>
                </div>
                <div className="tw-flex tw-gap-1">
                    <span className="tw-font-semibold">Peril:</span>
                    <span>{data.peril}</span>
                </div>
            </div>
        }
        canCollapse
        defaultIsCollapsed
    >
        <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2">
            <ClaimsField label="Loss Description" value="Hurricane Tyler" />
            <div className="tw-col-span-2" />
            <ClaimsField label="Date of Loss" value="04/15/2018" />
            <ClaimsField label="Date Reported" value="04/15/2018" />
            <ClaimsField label="Date Closed" value="" />
            <ClaimsField label="Date Reopened" value="" />

            <label>Loss Type</label>
            <Select
                selectedItem={['CAT', 'Cat']}
                items={[
                    ['CAT', 'Cat'],
                    ['AOP', 'AOP'],
                    ['EOB', 'EOB'],
                ]}
                disabled
                className="tw-pl-2"
                onChange={noop}
            />
            <ClaimsField label="Cause of Loss" value="Windstorm - Named Storm" />
            <ClaimsField label="Cat Event Name" value="Hurricane Tyler" />
            <ClaimsField label="Deductible" value="2%" />
            <ClaimsField label="Loss Paid" value={293000} isCurrency />
            <ClaimsField label="Loss Reserve" value={150000} isCurrency />
            <ClaimsField label="Expense Paid" value={5000} isCurrency />
            <ClaimsField label="Expense Reserve" value={2000} isCurrency />
            <ClaimsField label="Subrogation" value={null} isCurrency />
            <ClaimsField label="Total Incurred" value={450000} isCurrency />

            <ClaimsRadioButtonField label="Litigation" value="no" name="litigation" />
            <ClaimsRadioButtonField label="Coverage Issues" value="yes" name="coverage_issues" />
            <ClaimsRadioButtonField label="Claim Denied" value="yes" name="claim_denied" />
            <ClaimsRadioButtonField label="CRN" value="yes" name="crn" />
            <ClaimsRadioButtonField label="Public Adjuster" value="yes" name="public_adjuster" />
            <ClaimsRadioButtonField label="AOB" value="no" name="aob" />

            <ClaimsField label="Adjuster Name" value="Michael Thompson" />
            <ClaimsField label="Adjuster Email" value="mthompson@gmail.com" />
            <ClaimsField label="Adjuster Phone" value="(909) 816-4980" />
        </div>
    </Section>
);

const ClaimsField = ({
    label,
    value,
    isCurrency,
    disabled = true,
}: {
    label?: string;
    value: unknown;
    isCurrency?: boolean;
    type?: string;
    disabled?: boolean;
}) => (
    <>
        <label>{label}</label>
        <BasicInput
            value={isNullish(value) && isCurrency ? '-' : (value as string)}
            disabled={disabled}
            className="tw-px-2"
            dollarBefore={isCurrency}
            maskOptions={isCurrency ? { ...MASKS['CURRENCY_INTEGER'], numeralPositiveOnly: false } : undefined}
            rightAlignText={false}
        />
    </>
);

export const ClaimsPage = () => {
    // TODO: Fetch claims data
    const { currentQuoteId } = useAppContext();

    const { data: sedgwickClaim, isLoading, isUninitialized } = useSedgwickClaimQuery({ quoteId: currentQuoteId }, { skip: !currentQuoteId });

    if (isLoading || isUninitialized) {
        return (
            <DefaultAccountLayout>
                <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center">
                    <Spinner size="xl" />
                </div>
            </DefaultAccountLayout>
        );
    }

    const {
        total_claims,
        total_loss_paid,
        total_loss_reserve,
        total_expense_paid,
        total_expense_reserve,
        total_subro_recovery,
        total_incurred,
        inception_date,
        expiration_date,
    } = sedgwickClaim;

    let policy_period = '';
    if (inception_date) {
        policy_period = formatDateString(inception_date) + ' - ' + formatDateString(expiration_date);
    }

    return (
        <DefaultAccountLayout>
            <div className="[&_*]:tw-text-left [&>*:not(#header)]:tw-max-w-[1400px]">
                <div className="tw-pb-2 tw-px-2">
                    <Breadcrumbs />
                </div>

                <div id="header" className="tw-w-full tw-text-black tw-bg-gray-100 tw-p-4">
                    <h2 className="tw-text-[24px]">Claims</h2>
                </div>

                <div className="tw-sticky tw-top-0 tw-z-10 tw-bg-white">
                    <div className="tw-py-2 tw-px-4">
                        <Section header="Total Claims for All Years">
                            <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2 [&>*:nth-child(even)]:tw-ml-[-20px]">
                                <ClaimsField label="Total Claims" value={total_claims > 0 ? total_claims : 'No Claims'} />
                                <ClaimsField label="Policy Period" value={policy_period} />
                                <ClaimsField label="Loss Paid" value={total_loss_paid} isCurrency />
                                <ClaimsField label="Loss Reserve" value={total_loss_reserve} isCurrency />
                                <ClaimsField label="Expense Paid" value={total_expense_paid} isCurrency />
                                <ClaimsField label="Expense Reserve" value={total_expense_reserve} isCurrency />
                                <ClaimsField label="Subrogation" value={total_subro_recovery} isCurrency />
                                <ClaimsField label="Total Incurred" value={total_incurred} isCurrency />
                            </div>
                        </Section>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-w-full tw-px-4 tw-py-2 tw-overflow-auto tw-gap-4">
                    <PolicyPeriodSection
                        data={{ policy_number: 'VETGF01030240', policy_period: '4/15/2024 - 4/15/2025', number_claims: 2, incurred: 500000.2 }}
                    >
                        <ClaimSection data={{ claim_number: 4198267, status: 'Open', peril: 'Windstorm - Named Storm', incurred: 450000 }} />
                        <ClaimSection data={{ claim_number: 4198252, status: 'Closed', peril: 'Water Damage', incurred: 50000 }} />
                    </PolicyPeriodSection>

                    <PolicyPeriodSection data={{ policy_number: 'VETGF01030230', policy_period: '4/15/2023 - 4/15/2024', number_claims: 0, incurred: 0 }} />
                </div>
            </div>
        </DefaultAccountLayout>
    );
};
