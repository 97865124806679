import { useForm } from 'react-hook-form';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultFooter, DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { TwBreak, Label, SubHeader, TwGrid, Value } from '../../../pricing-page/PricingGrid';
import { PolicyCoverageType, QuoteResult, SetOverridesArgs } from '../../../../ts-types/ApiTypes';
import { useSetFinalPremiumOverridesMutation } from '../../../../services/apiSlice';
import { Button } from '../../../pricing-page/utils';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { SubmissionDataType } from '../../../../ts-types/DataTypes';
import { useQuoteId, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../../../hooks/quotes';
import { useSubmissionIsEditable } from '../../../../hooks/submissions';
import { PRICING_DEBOUNCE_TIME } from '../../../../constants/PricingConstants';
import { useChangeEffect, useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { BlurSubmitFormInput, BlurSubmitFormProps } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/BlurSubmitFormInput';

const DEPRECATED_FIELDS = {
    total_building_loss_cost_override: 'Building Loss Cost',
    property_enhancement_loss_cost_override: 'PEE Loss Cost',
    equipment_breakdown_loss_cost_override: 'EB Loss Cost',
    terror_loss_cost_override: 'Terror Loss Cost',
    limit_of_insurance_credit_override: 'Limit of Insurance Credit',
    other_debits_credits_override: 'Other Debits/Credits Credit',
    loss_history_credit_override: 'Loss History Credit',
    spread_of_risk_credit_override: 'Spread of Risk Credit',
    total_premium_override: 'Charged Premium Before Terror',
    air_cat_net_premium_override: 'AIR Cat Net Prem',
    rms_cat_net_premium_override: 'RMS Cat Net Prem',
    air_cat_net_premium_without_flood_override: 'AIR Cat Net Prem w/o Flood',
    rms_cat_net_premium_without_flood_override: 'RMS Cat Net Prem w/o Flood',
    layered_pricing_factor_override: 'Layered Pricing Factor',
    inland_flood_loss_cost_override: 'Inland Flood LC',
    air_cat_loss_cost_override: 'AIR Cat Loss Cost',
    air_broker_cat_loss_cost_override: 'AIR Broker Cat Loss Cost',
    business_strategy_override: 'Business Strategy',
};

const DeprecatedField = ({
    disabled,
    field,
    label,
    value,
    finalPremiumId,
    quoteId,
}: {
    disabled: boolean;
    field: string;
    label: string;
    value: string;
    finalPremiumId: number;
    quoteId: number;
}) => {
    const [triggerSetOverride, { isLoading: isUpdating }] = useSetFinalPremiumOverridesMutation();
    return (
        <>
            <Label className="tw-col-span-2">{label}</Label>
            <Value value={value} format={{ type: 'number', delimiter: ',' }} />
            <div className="w-100 pad-left-1">
                <Button
                    disabled={disabled}
                    className="blue w-100 min-height-0 pad-025"
                    onClick={() => triggerSetOverride({ id: finalPremiumId, quoteId, data: { [field]: null } })}
                >
                    {isUpdating ? <Spinner color="white" /> : 'Clear'}
                </Button>
            </div>
        </>
    );
};

const getPercentOfModel = (modelValue: string, overrideValue: string) => {
    if (!modelValue) {
        return '';
    } else if (!overrideValue) {
        return '100';
    }
    return ((Number(overrideValue) / Number(modelValue)) * 100).toFixed(1);
};

type ModalOverrideProps = {
    notIncluded?: boolean;
} & BlurSubmitFormProps<FormValues>;

const ModalOverride = ({ disabled, notIncluded, onReset, onSubmit, ...inputProps }: ModalOverrideProps) => {
    if (notIncluded) {
        return <div>Not Included</div>;
    }
    return (
        <BlurSubmitFormInput<FormValues>
            className="tw-w-16 pricing-row-height"
            disabled={disabled}
            maskOptions={MASKS['CURRENCY_INTEGER']}
            onReset={onReset}
            onSubmit={onSubmit}
            rightAlignText
            {...inputProps}
        />
    );
};

const OVERRIDES = [
    'air_broker_cat_aal_override',
    'air_cat_aal_override',
    'equipment_breakdown_external_premium_override',
    'rms_broker_cat_aal_override',
    'rms_cat_aal_override',
    'terror_premium_override',
] as const;

type SubmitValues = Pick<SetOverridesArgs, (typeof OVERRIDES)[number]>;

const getFormDefaults = (policyCoverage: PolicyCoverageType) => {
    const formValues = Object.fromEntries(OVERRIDES.map(key => [key, policyCoverage[key] ?? ''])) as FormValues;
    return formValues;
};

type footerProps = {
    onClose: (close: boolean) => void;
    isDestructive: boolean;
    disableConfirm: boolean;
};

type bodyPropTypes = {
    modalData: {
        quoteData: QuoteResult;
        quoteId: number;
        currentSubmission: SubmissionDataType;
    };
} & footerProps;

type FormValues = { [P in (typeof OVERRIDES)[number]]: string };

const OverridesBody = ({ modalData, ...props }: bodyPropTypes) => {
    const quoteId = useQuoteId();
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();
    const formDefaultValues = getFormDefaults(policyCoverage);

    const { control, formState, handleSubmit, resetField } = useForm<FormValues>({
        defaultValues: formDefaultValues,
    });
    const { dirtyFields } = formState;

    const [setOverrides] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });
    const debouncedSetOverrides = useDebounceCallback((data: SubmitValues) => setOverrides({ id: finalPremium.id, quoteId, data }), PRICING_DEBOUNCE_TIME);

    // Reset override default values when changed via input form or via external event
    // Ensure that we only reset fields that have changed to avoid wiping out user input
    useChangeEffect(formDefaultValues, (nextDefaults, prevDefaults) => {
        Object.keys(nextDefaults).forEach((key: keyof FormValues) => {
            if (!prevDefaults || prevDefaults[key] !== nextDefaults[key]) {
                resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    const { disableConfirm, ...footerProps } = props;

    const isEditable = useSubmissionIsEditable();
    const disabled = !isEditable;

    if (!finalPremium || !policyCoverage) {
        return (
            <div className="flex column jc_c ai_c pad-2">
                <h3>ERROR</h3>
                <p>Missing pricing data needed for pricing overrides.</p>
                <DefaultFooter modalData={{ confirmLabelTitle: 'Close' }} {...props} />
            </div>
        );
    }

    const deprecatedFields = Object.entries(DEPRECATED_FIELDS)
        .map(([field, label]) => {
            const value = finalPremium[field];
            if (!value) {
                return null;
            }
            return (
                <DeprecatedField disabled={disabled} key={field} field={field} label={label} value={value} finalPremiumId={finalPremium.id} quoteId={quoteId} />
            );
        })
        .filter(component => component !== null);

    const { occurrence_participation } = policyCoverage;
    const { air_broker_cat_aal, air_cat_aal, equipment_breakdown_premium_adj_for_participation_model, rms_broker_cat_aal, rms_cat_aal, terror_premium } =
        finalPremium;
    const {
        air_broker_cat_aal_override,
        air_cat_aal_override,
        equipment_breakdown,
        equipment_breakdown_external_premium_override,
        rms_broker_cat_aal_override,
        rms_cat_aal_override,
        terror,
        terror_premium_override,
    } = policyCoverage;

    const submitHandler = (data: FormValues) => {
        const dirtyData = Object.fromEntries(Object.entries(data).filter(([key, _value]) => key in dirtyFields));
        if (Object.keys(dirtyData).length === 0) {
            return;
        }
        const cleanedSubmitData = {};
        Object.keys(dirtyData).map(key => {
            const targetValue = data[key]?.replace(/,/g, '') ?? '';
            cleanedSubmitData[key] = targetValue !== '' ? targetValue : null;
        });
        if (Object.keys(cleanedSubmitData).length === 0) {
            return;
        }
        debouncedSetOverrides({ ...(cleanedSubmitData as SubmitValues) });
    };

    const participationLabel = () => `(After ${occurrence_participation}% Share)`;

    return (
        <div className="margin-bottom pricing-wrapper">
            <div className="info-block has-normal-btn pad-1 no-border tw-max-w-[500px]">
                <TwGrid className="tw-grid-cols-[4fr_2fr_3fr_2fr] tw-justify-items-end tw-gap-x-2 tw-gap-y-1">
                    <SubHeader className="tw-w-full tw-text-left">Description</SubHeader>
                    <SubHeader className="tw-w-full tw-text-right">Model</SubHeader>
                    <SubHeader className="tw-w-full tw-text-right">Override</SubHeader>
                    <SubHeader className="tw-w-full tw-text-right">% of Model</SubHeader>

                    <Label>AIR AAL</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={air_broker_cat_aal} />
                    <ModalOverride
                        disabled={disabled}
                        control={control}
                        name="air_broker_cat_aal_override"
                        onReset={() => resetField('air_broker_cat_aal_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={getPercentOfModel(air_broker_cat_aal, air_broker_cat_aal_override)}
                    />

                    <Label>AIR Risk-Adjusted AAL</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={air_cat_aal} />
                    <ModalOverride
                        disabled={disabled}
                        control={control}
                        name="air_cat_aal_override"
                        onReset={() => resetField('air_cat_aal_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={getPercentOfModel(air_cat_aal, air_cat_aal_override)}
                    />

                    <Label>RMS AAL</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={rms_broker_cat_aal} />
                    <ModalOverride
                        disabled={disabled}
                        control={control}
                        name="rms_broker_cat_aal_override"
                        onReset={() => resetField('rms_broker_cat_aal_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={getPercentOfModel(rms_broker_cat_aal, rms_broker_cat_aal_override)}
                    />

                    <Label>RMS Risk-Adjusted AAL</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={rms_cat_aal} />
                    <ModalOverride
                        disabled={disabled}
                        control={control}
                        name="rms_cat_aal_override"
                        onReset={() => resetField('rms_cat_aal_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={getPercentOfModel(rms_cat_aal, rms_cat_aal_override)}
                    />

                    <TwBreak className="tw-w-full" />

                    <Label className="tw-flex tw-gap-1 tw-items-center">
                        <span>EB</span>
                        {occurrence_participation !== 100 && <span>{participationLabel()}</span>}
                    </Label>
                    <Value
                        format={{ type: 'number', delimiter: ',' }}
                        value={equipment_breakdown ? equipment_breakdown_premium_adj_for_participation_model : null}
                    />
                    <ModalOverride
                        disabled={disabled}
                        notIncluded={!equipment_breakdown}
                        control={control}
                        name="equipment_breakdown_external_premium_override"
                        onReset={() => resetField('equipment_breakdown_external_premium_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={
                            equipment_breakdown
                                ? getPercentOfModel(
                                      equipment_breakdown_premium_adj_for_participation_model?.toFixed(0),
                                      equipment_breakdown_external_premium_override
                                  )
                                : null
                        }
                    />

                    <Label className="tw-flex tw-gap-1 tw-items-center">
                        <span>Terror</span>
                        {occurrence_participation !== 100 && <span>{participationLabel()}</span>}
                    </Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={terror ? terror_premium : null} />
                    <ModalOverride
                        disabled={disabled}
                        notIncluded={!terror}
                        control={control}
                        name="terror_premium_override"
                        onReset={() => resetField('terror_premium_override')}
                        onSubmit={handleSubmit(submitHandler)}
                    />
                    <Value
                        format={{ type: 'number', placeholder: 'N/A', decimals: 1, postfix: '%' }}
                        value={terror ? getPercentOfModel(terror_premium, terror_premium_override) : null}
                    />

                    {deprecatedFields.length > 0 && (
                        <>
                            <TwBreak className="tw-w-full" />

                            <Label className="tw-col-span-full sub-header margin-0 pad-0">Deprecated Overrides</Label>
                            <Label className="tw-col-span-full text-xs-no-lh">These deprecated overrides are no longer editable/supported by VIKI.</Label>
                            <Label className="tw-col-span-full text-xs-no-lh margin-bottom-05">They should be removed from any active quotes.</Label>

                            {deprecatedFields}
                        </>
                    )}
                </TwGrid>
            </div>

            <DefaultFooter
                modalData={{ confirmLabelTitle: 'Close', confirmButtonClass: 'tw-bg-gray-200 tw-text-gray-800', ...footerProps }}
                disableConfirm={Object.keys(dirtyFields).length > 0}
                {...props}
            />
        </div>
    );
};

let modalTypeObj = {
    Body: OverridesBody,
    Header: DefaultHeaderStatic('Pricing Overrides'),
};
registerType(VIRA_MODAL_TYPES.OVERRIDE_MODAL, modalTypeObj);
export default modalTypeObj;
