import { CatAnalysisResults, OsmPmlEventResults, ReferralHistoryResult, SedgwickClaimResults } from '../../ts-types/ApiTypes';
import { QuoteBindingRequirement } from '../../ts-types/DataTypes';
import api, { createTagsFromResult } from '../apiSlice';

export const coverageOptionApi = api.injectEndpoints({
    endpoints: builder => ({
        addCoverageOption: builder.mutation<unknown, { submission_id: number; description: string }>({
            query: ({ submission_id, description }) => ({
                url: `v1/quotes/`,
                method: 'POST',
                data: { submission_id, description },
                hideDialogForErrors: true,
            }),
            invalidatesTags: (result, _error, { submission_id }) => createTagsFromResult(result, [{ type: 'Submission', id: submission_id }]),
        }),
        cloneCoverageOption: builder.mutation<{ new_id: number; submission_id: number }, { quote_id: number; description: string }>({
            query: ({ quote_id, description }) => ({ url: `v1/quotes/clone/`, method: 'POST', data: { quote_id, description }, hideDialogForErrors: true }),
            invalidatesTags: [{ type: 'BackgroundTask', id: 'ACCOUNT_TASKS' }],
        }),
        quoteConditions: builder.query<unknown, unknown>({
            query: ({ id, include_custom_quote_conditions = true }) => ({
                url: `v2/quote_form_data?quote_id=${id}`,
                params: { include_custom_quote_conditions: include_custom_quote_conditions ? 'True' : 'False' },
                method: 'get',
            }),
            providesTags: (_result, _error, { id }) => [{ type: 'QuoteConditions', id }],
        }),
        quoteBindingRequirements: builder.query<QuoteBindingRequirement[], { quoteId: number }>({
            query: ({ quoteId }) => ({
                url: `v1/quotes/${quoteId}/binding_requirements/`,
                method: 'get',
            }),
            providesTags: (_result, _error, { quoteId }) => [{ type: 'BindingRequirements', id: quoteId }],
        }),
        changeBindingRequirement: builder.mutation<
            QuoteBindingRequirement,
            { quoteId: number; brId: number; active?: boolean } & Pick<QuoteBindingRequirement, 'label' | 'description' | 'group_id'>
        >({
            query: ({ quoteId, brId, label, description, active, group_id }) => ({
                url: `v1/quotes/${quoteId}/change_binding_requirement/`,
                data: { br_id: brId, label, description, active, group_id },
                method: 'put',
                hideDialogForErrors: true,
            }),
            invalidatesTags: (_result, _error, { quoteId }) => [{ type: 'BindingRequirements', id: quoteId }],
        }),
        addBindingRequirement: builder.mutation<
            QuoteBindingRequirement,
            { quoteId: number } & Pick<QuoteBindingRequirement, 'label' | 'description' | 'group_id'>
        >({
            query: ({ quoteId, label, description, group_id }) => ({
                url: `v1/quotes/${quoteId}/add_binding_requirement/`,
                data: { label, description, group_id },
                method: 'post',
                hideDialogForErrors: true,
            }),
            invalidatesTags: (_result, _error, { quoteId }) => [{ type: 'BindingRequirements', id: quoteId }],
        }),
        referralHistory: builder.query<ReferralHistoryResult, { quoteId: number }>({
            query: ({ quoteId }) => ({
                url: 'v1/referral_history',
                method: 'GET',
                params: { quote: quoteId },
            }),
        }),
        catAnalysis: builder.query<CatAnalysisResults, { quoteId: number }>({
            query: ({ quoteId }) => ({
                url: `v1/quotes/${quoteId}/get_cat_analysis/`,
                method: 'GET',
            }),
            providesTags: (_result, _error, { quoteId }) => [{ type: 'CatAnalysis', id: quoteId }],
        }),
        osmPmlTable: builder.query<OsmPmlEventResults, unknown>({
            query: ({ quoteId }) => ({
                url: `v1/quotes/${quoteId}/get_osm_pml_table/`,
                method: 'GET',
            }),
            providesTags: (_result, _error, { quoteId }) => [{ type: 'CatAnalysis', id: quoteId }],
        }),
        sedgwickClaim: builder.query<SedgwickClaimResults, { quoteId: number }>({
            query: ({ quoteId }) => ({
                url: 'v1/sedgwick_claim',
                method: 'GET',
                params: { quote: quoteId },
            }),
        }),
    }),
});

export const {
    useAddCoverageOptionMutation,
    useCloneCoverageOptionMutation,
    useQuoteBindingRequirementsQuery,
    useQuoteConditionsQuery,
    useChangeBindingRequirementMutation,
    useAddBindingRequirementMutation,
    useReferralHistoryQuery,
    useCatAnalysisQuery,
    useOsmPmlTableQuery,
    useSedgwickClaimQuery,
} = coverageOptionApi;
